// global config, only for export

// * Home URL (Default)
export const DASHBOARD_URL = '/dashboard'

// * Login URL (Default)
export const LOGIN_URL = '/login'

// * Admin Login URL
export const ADMIN_LOGIN_URL = '/adminLogin'

// * Sign Up URL (Default)
export const SIGN_UP_URL = '/sign-up'

// * 404 URL (Default)
export const NOT_FOUND_URL = '/404'

// * ROUTER WHITE LIST
export const ROUTER_WHITE_LIST: string[] = ['/500', '/404', '/403', '/401', '/login', '/sign-up', '/adminLogin']

// Layout base on user role
type RoleToLayoutMap = {
  [role in 'admin' | 'unverified_lawyer' | 'lawyer' | 'staff' | 'unsetPinUser']: 'admin' | 'lawyer' | 'inactive' | 'default';
}
export const roleToLayoutMap: RoleToLayoutMap = {
  admin: 'admin',
  lawyer: 'lawyer',
  unverified_lawyer: 'inactive',
  staff: 'default',
  unsetPinUser: 'default',
}

// When user has multiple roles, the layout will be the first one in the array
export const roleLayoutPriority: ('admin' | 'unverified_lawyer' | 'lawyer' | 'staff' | 'unsetPinUser')[] = ['admin', 'unverified_lawyer', 'lawyer', 'staff', 'unsetPinUser']
