import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { VueSignaturePad } from 'vue-signature-pad'
import App from './App.vue'
import directives from '@/directives/index'
import pinia from '@/stores'
import 'element-plus/dist/index.css'
import '@/styles/main.scss'

import router from '@/routers'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue))
  app.component(key, component)

app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.use(directives)
app.component('VueSignaturePad', VueSignaturePad)

app.component('VueDatePicker', VueDatePicker)

app.mount('#app')
