import type { RouteRecordRaw } from 'vue-router'
import { ADMIN_LOGIN_URL, DASHBOARD_URL, LOGIN_URL, SIGN_UP_URL } from '@/config/config'

/**
 *  static router
 */

export const staticRouter: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: DASHBOARD_URL,
  },
  {
    path: LOGIN_URL,
    name: 'login',
    meta: {
      title: 'login',
    },
    component: () => import('@/pages/login/index.vue'),
  },
  {
    path: SIGN_UP_URL,
    name: 'sign-up',
    meta: {
      title: 'sign-up',
    },
    component: () => import('@/pages/signup/index.vue'),
  },
  {
    path: ADMIN_LOGIN_URL,
    name: 'adminlogin',
    meta: {
      title: 'Admin Login',
    },
    component: () => import('@/pages/adminLogin/index.vue'),
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/layouts/index.vue'),
    redirect: DASHBOARD_URL,
    children: [],
  },
]

/**
 * errorRouter
 */
export const errorRouter = [
  {
    path: '/403',
    name: '403',
    component: () => import('@/components/ErrorMessage/403.vue'),
    meta: {
      title: '403',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/ErrorMessage/404.vue'),
    meta: {
      title: '404',
    },
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/components/ErrorMessage/500.vue'),
    meta: {
      title: '500',
    },
  },
  // To resolve the "Route Warning on Page Refresh" issue
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/ErrorMessage/404.vue'),
  },
]
