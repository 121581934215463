import { ElMessage } from 'element-plus'

export const DefaultError = 'Request failed! Please try again later.'

export const ERROR_MESSAGES: { [key: number]: string } = {
  400: 'Bad request! Please check your input and try again.',
  401: 'Unauthorized! Please log in to access this resource.',
  403: 'Forbidden! You do not have permission to access this resource.',
  404: 'Not found! The resource you are looking for does not exist.',
  405: 'Method not allowed! Please check the HTTP method used for this request.',
  408: 'Request timeout! Please try again later.',
  500: 'Internal server error! Please try again later.',
  502: 'Bad gateway! Please try again later.',
  503: 'Service unavailable! Please try again later.',
  504: 'Gateway timeout! Please try again later.',
}

export const handleErrStatus = (status: number): void => {
  const errorMessage = ERROR_MESSAGES[status] || DefaultError
  ElMessage.error(errorMessage)
  console.error(errorMessage)
}
