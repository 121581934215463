import { defineStore } from 'pinia'
import type { LayoutType } from '../interface'
import type { User, UserInfo } from '@/types/user'
import { GlobalStore } from '@/stores'
import { verifyUser } from '@/api/modules/user'
import { URLEnum } from '@/enums/httpEnum'
import { roleLayoutPriority, roleToLayoutMap } from '@/config/config'

export const AuthStore = defineStore({
  id: 'authStore',
  state: () => ({
    roles: [] as string[] | string,
    id: null as number | null,
    email: '' as string,
    firstname: '' as string,
    lastname: '' as string,
    profile: '' as string,
    status: '' as string,
    mobile: '' as string | null,
    userId: null as number | null,
    pin: '1111',
  }),
  getters: {
  },
  actions: {
    async getUserInfo() {
      const globalStore = GlobalStore()
      const { data } = await verifyUser()
      const { user, userInfo, roles }: { user: User; userInfo: UserInfo; roles: string[] } = data ?? {}
      this.id = user.id
      this.roles = roles
      this.userId = user.id
      this.email = user.email
      this.firstname = userInfo.first_name
      this.lastname = userInfo.last_name
      this.mobile = userInfo.mobile
      this.status = userInfo.verification_status
      this.profile = `${URLEnum.PUBLIC_SRC_URL}${userInfo.profile}`

      const rolesArray = Array.isArray(this.roles) ? this.roles : [this.roles]
      const highestPriorityRole = roleLayoutPriority.find(role => rolesArray.includes(role))
      const layout: LayoutType = highestPriorityRole ? roleToLayoutMap[highestPriorityRole] : 'default'
      globalStore.setThemeConfig({
        ...globalStore.themeConfig,
        layout,
      })
    },
    async signOut() {
      const globalStore = GlobalStore()
      globalStore.removeToken()
      window.location.reload()
    },
    async tokenSessionExpired() {
      const globalStore = GlobalStore()
      globalStore.removeToken()
      window.location.reload()
    },
  },
})
