import axios from 'axios'
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import { ElMessage } from 'element-plus'
import { handleErrStatus } from './helper/errorStatus'
import type { ResultData } from './interface'
import { LOGIN_URL } from '@/config/config'
import { showFullScreenLoading, tryHideFullScreenLoading } from '@/config/serviceLoading'
import { ResultEnum, URLEnum } from '@/enums/httpEnum'
import router from '@/routers'
import { GlobalStore } from '@/stores'
import { AuthStore } from '@/stores/modules/auth'
import { refreshTokenApi } from '@/api/modules/refreshToken'

const config = {
  // default address, base on .env file
  baseURL: URLEnum.BASE_API_URL as any,
  // request timeout 30s
  timeout: ResultEnum.TIMEOUT as number,
  // Api not handling cookies, set it false here
  withCredentials: false,
}

class RequestHttp {
  service: AxiosInstance
  public constructor(config: AxiosRequestConfig) {
    // create an axios instance
    this.service = axios.create(config)

    // set default transformRequest to FormData
    // this.service.defaults.transformRequest = [obj => Qs.stringify(obj, { indices: false })]

    /**
     * @description: request interceptor
     * client request -> [request interceptor] -> server
     * token verify(JTW): receive token from server -> store in localstorage/pinia
     */

    this.service.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const globalStore = GlobalStore()
        // if current request not need loading, add param { headers: { noLoading: true } }, to disable loading
        config.headers!.noLoading || showFullScreenLoading()

        // if token set in request header
        const token = globalStore.token
        if (config?.headers && token) {
          config.headers.Authorization = `Bearer ${token}`
          config.headers['Content-Type'] = 'application/json'
        }
        return config
      },
      (error: AxiosError) => {
        return Promise.reject(error)
      },
    )
    /**
     * @description response interceptor
     *  server respond -> [response interceptor] -> client
     */
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        const { data } = response
        const globalStore = GlobalStore()
        // after request, hide the loading
        tryHideFullScreenLoading()
        // * fail to Login (code == 401)
        if (data.code === ResultEnum.OVERDUE) {
          ElMessage.error(data.message)
          globalStore.setToken('')
          router.replace(LOGIN_URL)
          return Promise.reject(data)
        }
        // * global error handle (Avoiding data stream errors in file downloads without code.)
        if (data.code && data.code !== ResultEnum.SUCCESS) {
          ElMessage.error(data.message)
          return Promise.reject(data)
        }

        if (data.status !== 1) {
          if (data.message)
            ElMessage.error(data.message)
          return Promise.reject(data)
        }
        // * request success
        return data
      },
      async (error: AxiosError) => {
        const { response } = error
        tryHideFullScreenLoading()
        // handle timeouts and network errors
        if (error.message.includes('timeout'))
          ElMessage.error('Request Timeout, Please try again')
        if (error.message.includes('Network Error'))
          ElMessage.error('Network Error, Please check your network connection')
        if (response) {
          // if error code is 401
          if (response.status === 401 && (response.data as any).message === 'Token is expired') {
            const authStore = AuthStore()
            const globalStore = GlobalStore()
            const { config } = error
            // if the 401 caused by token expire, try get new token
            try {
              const response = await refreshTokenApi()
              if (!response.data.access_token)
                throw new Error('Token Refresh Failed')
              globalStore.setToken(response.data.access_token)
              // Retry the original request
              if (config) {
                config.headers.Authorization = response.data.access_token
                return this.service(config)
              }
            }
            catch (err) {
              authStore.tokenSessionExpired()
              return Promise.reject(err)
            }
          }
          else {
            handleErrStatus(response.status)
          }
        }
        // handle server/network disconnections by redirecting to a disconnection page
        if (!window.navigator.onLine)
          router.replace('/500')
        return Promise.reject(error)
      },
    )
  }

  get<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
    return this.service.get(url, { params, ..._object })
  }

  post<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
    return this.service.post(url, params, _object)
  }

  patch<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
    return this.service.patch(url, params, _object)
  }

  put<T>(url: string, params?: object, _object = {}): Promise<ResultData<T>> {
    return this.service.put(url, params, _object)
  }

  delete<T>(url: string, params?: any, _object = {}): Promise<ResultData<T>> {
    return this.service.delete(url, { params, ..._object })
  }
}

export default new RequestHttp(config)
