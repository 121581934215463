import type { ApiResponse } from '../interface'
import http from '@/api'

export const verifyUser: any = () => {
  return http.get('me', {}, { headers: { noLoading: true } })
}

export const getUserClientsApi = <TResult> (): Promise<ApiResponse<TResult>> => {
  return http.get('user-clients', {}, { headers: { noLoading: true } })
}

export const getUserApi = <TResult>(
  id: number,
): Promise<ApiResponse<TResult>> => {
  return http.get<TResult>(
    `users/${id}`,
    {},
    { headers: { noLoading: true } },
  )
}

export const updateUserApi = <TResult, TParam extends object>(
  params: TParam,
  id: number,
): Promise<ApiResponse<TResult>> => {
  return http.put<TResult>(
    `users/${id}`,
    params,
    { headers: { noLoading: true } },
  )
}

export const getUserFilteredClientsApi = <TResult, TParam extends object> (
  params: TParam,
): Promise<ApiResponse<TResult>> => {
  return http.get('user-clients/filter', params, { headers: { noLoading: true } })
}

export const addClientApi = <TResult, TParam extends object>(params: TParam) => {
  return http.post<TResult>('user-clients', params, { headers: { noLoading: true } })
}

export const updateClientApi = <TResult, TParam extends object>(
  params: TParam,
  id: number,
): Promise<ApiResponse<TResult>> => {
  return http.put<TResult>(
    `user-clients/${id}`,
    params,
    { headers: { noLoading: true } },
  )
}

export const deleteClientApi = <TResult>(id: number) => {
  return http.delete<TResult>(`user-clients/${id}`, {}, { headers: { noLoading: true } })
}

export const getUserFilteredPreCommentsApi = <TResult, TParam extends object> (
  params: TParam,
): Promise<ApiResponse<TResult>> => {
  return http.get('user-pre-comments/filter', params, { headers: { noLoading: true } })
}

export const getPreCommentsApi = <TResult> (): Promise<ApiResponse<TResult>> => {
  return http.get('user-pre-comments', {}, { headers: { noLoading: true } })
}

export const editUserPreCommentApi = <TResult, TParam extends object>(
  params: TParam,
  id: number,
): Promise<ApiResponse<TResult>> => {
  return http.put<TResult>(
    `user-pre-comments/${id}`,
    params,
    { headers: { noLoading: true } },
  )
}

export const addUserPreCommentApi = <TResult, TParam extends object>(params: TParam) => {
  return http.post<TResult>('user-pre-comments', params, { headers: { noLoading: true } })
}

export const deleteUserPreCommentApi = <TResult>(id: number) => {
  return http.delete<TResult>(`user-pre-comments/${id}`, {}, { headers: { noLoading: true } })
}
