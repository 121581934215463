import type { RouteRecordRaw } from 'vue-router'

export const dynamicRouter: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/dashboard/index.vue'),
    meta: {
      title: 'Dashboard',
      roles: ['lawyer'],
      keepAlive: true,
    },
  },
  // Inactive User Dashboard
  {
    path: '/dashboard',
    name: 'inactiveDashboard',
    component: () => import('@/pages/inactive/index.vue'),
    meta: {
      title: 'Inactive Dashboard',
      roles: ['unverified_lawyer'],
    },
  },
  // Admin Dashboard
  {
    path: '/dashboard',
    name: 'adminDashboard',
    component: () => import('@/pages/adminDashboard/index.vue'),
    meta: {
      title: 'Admin Dashboard',
      roles: ['admin'],
    },
  },
  {
    path: '/case',
    meta: {
      title: 'case',
    },
    children: [
      {
        path: '/case/add',
        name: 'addCase',
        component: () => import('@/pages/case/addCase/index.vue'),
        meta: {
          title: 'Add Case',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/all',
        name: 'allCase',
        component: () => import('@/pages/case/allCases/index.vue'),
        meta: {
          title: 'AllCase',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/:caseId',
        name: 'viewCase',
        component: () => import('@/pages/case/[caseId]/index.vue'),
        meta: {
          title: 'View Case',
          roles: ['lawyer', 'admin'],
        },
      },
      {
        path: '/case/running',
        name: 'runningCase',
        component: () => import('@/pages/case/runningCases/index.vue'),
        meta: {
          title: 'Running Case',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/history',
        name: 'historyCase',
        component: () => import('@/pages/case/historyCases/index.vue'),
        meta: {
          title: 'History Case',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/archive',
        name: 'archivedCase',
        component: () => import('@/pages/case/archiveCases/index.vue'),
        meta: {
          title: 'Archived Case',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/terminated',
        name: 'terminatedCase',
        component: () => import('@/pages/case/terminatedCases/index.vue'),
        meta: {
          title: 'Terminated Case',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/shared',
        name: 'sharedCase',
        component: () => import('@/pages/case/sharedCases/index.vue'),
        meta: {
          title: 'Shared Case',
          roles: ['lawyer'],
        },
      },
      {
        path: '/case/search',
        name: 'searchCase',
        component: () => import('@/pages/case/searchCases/index.vue'),
        meta: {
          title: 'Search Case',
          roles: ['lawyer'],
        },
      },
    ],
  },
  {
    path: '/account',
    meta: {
      title: 'account',
    },
    children: [
      {
        path: '/account/profile',
        name: 'profile',
        component: () => import('@/pages/account/profile/index.vue'),
        meta: {
          title: 'Profile',
          roles: ['lawyer', 'unverified_lawyer'],
        },
      },
      {
        path: '/account/comment',
        name: 'comment',
        component: () => import('@/pages/account/comment/index.vue'),
        meta: {
          title: 'Comment',
          roles: ['lawyer'],
        },
      },
      {
        path: '/account/password',
        name: 'password',
        component: () => import('@/pages/account/password/index.vue'),
        meta: {
          title: 'Change Password',
          roles: ['lawyer', 'unverified_lawyer'],
        },
      },
      {
        path: '/account/pin',
        name: 'accountChangePIN',
        component: () => import('@/pages/account/pin/index.vue'),
        meta: {
          title: 'Change PIN',
          roles: ['lawyer', 'unverified_lawyer'],
        },
      },
      {
        path: '/account/clients',
        name: 'clients',
        component: () => import('@/pages/account/client/index.vue'),
        meta: {
          title: 'Clients',
          roles: ['lawyer'],
        },
      },
      {
        path: '/account/clients/:clientId',
        name: 'clientDetail',
        component: () => import('@/pages/account/client/[clientId]/index.vue'),
        meta: {
          title: 'Client Detail',
          roles: ['lawyer'],
        },
      },
    ],
  },
  {
    path: '/voi',
    name: 'voi',
    redirect: '/voi/all',
    meta: {
      title: 'Voi',
      roles: ['lawyer'],
    },
    children: [
      {
        path: '/voi/all',
        name: 'Vois',
        component: () => import('@/pages/voi/all/index.vue'),
        meta: {
          title: 'VOIs',
          roles: ['lawyer'],
        },
      },
      {
        path: '/voi/add',
        name: 'addVoi',
        component: () => import('@/pages/voi/add/index.vue'),
        meta: {
          title: 'Add VOI',
          roles: ['lawyer'],
        },
      },
      {
        path: '/voi/:voiId',
        name: 'voiView',
        component: () => import('@/pages/voi/[voiId]/index.vue'),
        meta: {
          title: 'View VOI',
          roles: ['lawyer'],
        },
      },
    ],
  },
  {
    path: '/pre',
    name: 'pre items',
    meta: {
      title: 'Pre Items',
      roles: ['lawyer'],
    },
    children: [
      {
        path: '/pre/status',
        name: 'preStatus',
        component: () => import('@/pages/pre/status/index.vue'),
        meta: {
          title: 'Pre Statuses',
          roles: ['lawyer'],
        },
      },
      {
        path: '/pre/paymentItem',
        name: 'prePaymentItem',
        component: () => import('@/pages/pre/paymentItem/index.vue'),
        meta: {
          title: 'Pre Payment Item',
          roles: ['lawyer'],
        },
      },
      {
        path: '/pre/status/:statusId',
        name: 'Pre Status',
        component: () => import('@/pages/pre/[statusId]/index.vue'),
        meta: {
          title: 'Pre Status',
          roles: ['lawyer'],
        },
      },
    ],
  },
  {
    path: '/paymentTimeline/:caseId',
    name: 'paymentTimeline',
    component: () => import('@/pages/paymentTimeline/index.vue'),
    meta: {
      title: 'Payment Timeline',
      roles: ['lawyer'],
    },
  },
  {
    path: '/pdf/:id',
    name: 'viewPdf',
    component: () => import('@/pages/pdf/index.vue'),
    meta: {
      title: 'View PDF',
      roles: ['lawyer', 'admin', 'staff'],
      isFull: true,
    },
  },
  {
    path: '/file/:name/:id',
    name: 'viewFile',
    component: () => import('@/pages/file/index.vue'),
    meta: {
      title: 'View File',
      roles: ['lawyer', 'admin', 'staff'],
      isFull: true,
    },
  },

  // Admin Routes
  {
    path: '/admin',
    name: 'admin',
    meta: {
      title: 'Admin',
      roles: ['admin'],
    },
    children: [
      {
        path: '/admin/lawyers',
        name: 'lawyers',
        component: () => import('@/pages/admin/lawyers/index.vue'),
        meta: {
          title: 'Lawyers',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/agents',
        name: 'agents',
        component: () => import('@/pages/admin/agents/index.vue'),
        meta: {
          title: 'Agents',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/clients',
        name: 'clients',
        component: () => import('@/pages/admin/clients/index.vue'),
        meta: {
          title: 'Clients',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/staff',
        name: 'staff',
        component: () => import('@/pages/admin/staff/index.vue'),
        meta: {
          title: 'Staff',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/cases',
        name: 'cases',
        component: () => import('@/pages/admin/cases/index.vue'),
        meta: {
          title: 'Cases',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/profile',
        name: 'profile',
        component: () => import('@/pages/admin/profile/index.vue'),
        meta: {
          title: 'Profile',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/password',
        name: 'password',
        component: () => import('@/pages/admin/password/index.vue'),
        meta: {
          title: 'Change Password',
          roles: ['admin'],
        },
      },
      {
        path: '/admin/pre',
        name: 'pre items',
        meta: {
          title: 'Pre Items',
        },
        children: [
          {
            path: '/admin/pre/status',
            name: 'preStatus',
            component: () => import('@/pages/admin/pre/status/index.vue'),
            meta: {
              title: 'Pre Statuses',
              roles: ['admin'],
            },
          },
          {
            path: '/admin/pre/status/:statusId',
            name: 'statusTodoList',
            component: () => import('@/pages/admin/pre/[statusId]/index.vue'),
            meta: {
              title: 'Status Todo List',
              roles: ['admin'],
            },
          },
          {
            path: '/admin/pre/paymentItem',
            name: 'prePaymentItem',
            component: () => import('@/pages/admin/pre/paymentItem/index.vue'),
            meta: {
              title: 'Pre Payment Timeline',
              roles: ['admin'],
            },
          },
        ],
      },
    ],
  },

  // Lock Screen Route
  {
    path: '/lockScreen',
    name: 'lockScreen',
    meta: {
      title: 'Lock Screen',
      roles: ['lawyer'],
      isFull: true,
    },
    component: () => import('@/pages/lockScreen/index.vue'),
  },

  // User with unset pin routes
  {
    path: '/dashboard',
    name: 'setPin',
    component: () => import('@/pages/setPin/index.vue'),
    meta: {
      title: 'setPin',
      roles: ['unsetPinUser'],
      isFull: true,
    },
  },
]
