import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  easing: 'ease', // animation easing function
  speed: 500, // self-defined animation speed
  showSpinner: true, // show loading spinner
  trickleSpeed: 200, // self-defined trickle speed
  minimum: 0.3, //  minimum progress
})

export default NProgress
