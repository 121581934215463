// * Request Enum Configuration

/**
 * @description: request setting
 */

export enum ResultEnum {
  SUCCESS = 200,
  ERROR = 500,
  OVERDUE = 401,
  TIMEOUT = 30000,
  TYPE = 'success',
}

/**
 * @description: request method
 */

export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

/**
 * @description: content type
 */

export enum ContentTypeEnum {
  // JSON
  JSON = 'application/json;charset=UTF-8',
  // FORM-DATA
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // FORM-DATA UPLOAD
  FORM_DATA = 'multipart/form-data;charset=UTF-8',
  // TEXT
  TEXT_PLAIN = 'text/plain;charset=UTF-8',
}

export enum URLEnum {
  BASE_API_URL = import.meta.env.VITE_BASE_API_URL,
  BASE_URL = import.meta.env.VITE_BASE_URL,
  PUBLIC_SRC_URL = import.meta.env.VITE_API_PUBLIC_SRC_URL,
  RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY,
}
