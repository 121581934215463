import { createRouter, createWebHistory } from 'vue-router'
import { errorRouter, staticRouter } from '@/routers/modules/staticRouter'
import { GlobalStore } from '@/stores'
import NProgress from '@/config/nprogress'
import { RouteStore } from '@/stores/modules/route'
import {
  ADMIN_LOGIN_URL,
  LOGIN_URL,
  NOT_FOUND_URL,
  ROUTER_WHITE_LIST,
  SIGN_UP_URL,
} from '@/config/config'
import { AuthStore } from '@/stores/modules/auth'

const router = createRouter({
  history: createWebHistory(),
  routes: [...staticRouter, ...errorRouter],
  strict: false,
  scrollBehavior: () => ({ left: 0, top: 0 }),
})

/**
 * @description router guard before each
 */

router.beforeEach(async (to, from, next) => {
  const globalStore = GlobalStore()
  const routeStore = RouteStore()

  // NProgress start
  NProgress.start()

  // set dynamic title
  const title = import.meta.env.VITE_APP_TITLE
  document.title = to.meta.title ? `${to.meta.title} - ${title}` : title

  // record last route, if current route is lock screen, don't record
  if (to.name !== 'lockScreen')
    routeStore.setLastRoute(to)

  // user enter login, if has token, redirect to next, else redirect to login
  if (to.path === LOGIN_URL || to.path === SIGN_UP_URL || to.path === ADMIN_LOGIN_URL) {
    if (globalStore.token)
      return next({ path: NOT_FOUND_URL })
    return next()
  }

  // check if current path in the white list, if yes, go to next
  if (ROUTER_WHITE_LIST.includes(to.path))
    return next()

  // check if has token, if not, redirect to login
  if (!globalStore.token)
    return next({ path: LOGIN_URL, replace: true })

  // check if lock screen is true, stay in lock screen page
  if (globalStore.lockScreen === true && to.name !== 'lockScreen') {
    if (router.hasRoute('lockScreen'))
      return next({ path: '/lockScreen', replace: true })
    else
      return next()
  }

  // if roles value is empty string or empty array, rolesEmpty is true
  const authStore = AuthStore()
  const rolesEmpty = Array.isArray(authStore.roles)
    ? authStore.roles.length === 0
    : authStore.roles === ''

  // if roles is empty, call api verify current user and retrieve user info, if failed signout user
  if (rolesEmpty) {
    try {
      await authStore.getUserInfo()
    }
    catch (error) {
      await authStore.signOut()
      return next(LOGIN_URL)
    }
  }

  // if dynamicRoutes is empty, generate dynamic routes
  if (routeStore.dynamicRoutes.length === 0) {
    const roles = authStore.roles
    await routeStore.generateDynamicRoutes(roles)
    return next({ ...to, replace: true })
  }

  next()
})

/**
 * @description router guard after each
 * */
router.afterEach(() => {
  NProgress.done()
})

/**
 * @description router guard on error
 * */
router.onError((error) => {
  NProgress.done()
  console.warn('route failed', error.message)
})

export default router
