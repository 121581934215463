import type { PersistedStateOptions } from 'pinia-plugin-persistedstate'

/**
 * @description pinia persist config
 * @param {String} key presist name
 * @param {Array} paths persist state name
 * @return persist config
 * */
const piniaPersistConfig = (key: string, paths?: string[]) => {
  const persist: PersistedStateOptions = {
    key,
    storage: localStorage,
    // storage: sessionStorage,
    paths,
  }
  return persist
}

export default piniaPersistConfig
