import { defineStore } from 'pinia'
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { filterAsyncRoutes } from '@/utils/route'
import { dynamicRouter } from '@/routers/modules/dynamicRouter'
import { staticRouter } from '@/routers/modules/staticRouter'
import router from '@/routers'

export const RouteStore = defineStore({
  id: 'routeStore',
  state: () => ({
    route: [] as RouteRecordRaw[],
    dynamicRoutes: [] as RouteRecordRaw[],
    lastRoute: undefined as RouteLocationNormalized | undefined,
  }),
  getters: {},
  actions: {
    async generateDynamicRoutes(roles: string[] | string) {
      // base on the user roles filter dynamic routes
      const accessedRoutes = filterAsyncRoutes(dynamicRouter, roles)
      this.dynamicRoutes = accessedRoutes
      this.route = [...staticRouter, ...accessedRoutes]
      // add routes to router
      this.dynamicRoutes.forEach((route: any) => {
        if (route.meta.isFull)
          router.addRoute(route)
        else
          router.addRoute('layout', route)
      })
    },
    setLastRoute(route: RouteLocationNormalized) {
      this.lastRoute = route
    },
  },
})
