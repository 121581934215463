import axios from 'axios'
import { ResultEnum, URLEnum } from '@/enums/httpEnum'
import { GlobalStore } from '@/stores'

/**
 * @description: refreshToken
 *  craete a new instance axios so it not conflict with the default axios instance header
 * @returns accessToken and refreshToken
 */
export const refreshTokenApi: any = async () => {
  const globalStore = GlobalStore()

  const refreshTokenConfig: { baseURL: any; timeout: number; headers?: any } = {
    baseURL: URLEnum.BASE_API_URL as any,
    timeout: ResultEnum.TIMEOUT as number,
  }

  if (globalStore.token) {
    refreshTokenConfig.headers = {
      Authorization: `Bearer ${globalStore.token}`,
    }
  }
  const refreshTokenHttp = axios.create(refreshTokenConfig)

  return await refreshTokenHttp.post('auth/refresh')
}
