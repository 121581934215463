import type { RouteRecordRaw } from 'vue-router'

/**
 * @description: Determine whether the user has permission to the route
 * @param roles router.meta.roles
 * @param route router object
 * @returns true or false
 */
export const hasPermission = (roles: string[] | string, route: any) => {
  // if the route does not set roles, it means that it is open to all
  if (!route.meta || !route.meta.roles)
    return true
  // if the user has the role, and the role includes the route, return true
  if (Array.isArray(roles))
    return roles.some(role => route.meta?.roles?.includes(role))
  else
    return route.meta?.roles?.includes(roles)
}

/**
 * @description: Filter all routes according to user roles
 * @param asyncRouterMap List of all routes
 * @param roles user roles
 * @returns authorized routes
 */
export const filterAsyncRoutes = (asyncRouterMap: RouteRecordRaw[], roles: string[] | string) => {
  const accessedRouters = asyncRouterMap.filter((route) => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length)
        route.children = filterAsyncRoutes(route.children, roles)
      return true
    }
    return false
  })
  return accessedRouters
}
