import { ElLoading } from 'element-plus'

/* method for globle loading */
let loadingInstance: ReturnType<typeof ElLoading.service>

/**
 * @description start Loading
 * */
const startLoading = () => {
  loadingInstance = ElLoading.service({
    fullscreen: true,
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

/**
 * @description end Loading
 * */
const endLoading = () => {
  loadingInstance.close()
}

/**
 * @description show full sreen loading
 * */
let needLoadingRequestCount = 0
export const showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0)
    startLoading()

  needLoadingRequestCount++
}

/**
 * @description hide full screen loading
 * */
export const tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0)
    return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0)
    endLoading()
}
