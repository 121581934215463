import { createPinia, defineStore } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import type { AssemblySizeType, GlobalState, ThemeConfigProps } from './interface'
import piniaPersistConfig from '@/config/piniaPersist'

export const GlobalStore = defineStore({
  id: 'globalStore',
  state: (): GlobalState => ({
    // access token
    token: '',
    // userInfo
    userInfo: '',
    // elementComponentSize
    assemblySize: 'default',
    // language
    language: '',
    // themeConfig
    lockScreen: false,
    themeConfig: {
      isDark: false,
      layout: 'default',
    },
  }),
  getters: {},
  actions: {
    // setToken
    setToken(token: string) {
      if (!token)
        return ''
      this.token = token
    },
    // setUserInfo
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo
    },
    // setAssemblySizeSizes
    setAssemblySizeSize(assemblySize: AssemblySizeType) {
      this.assemblySize = assemblySize
    },
    // updateLanguage
    updateLanguage(language: string) {
      this.language = language
    },
    // setThemeConfig
    setThemeConfig(themeConfig: ThemeConfigProps) {
      this.themeConfig = themeConfig
    },
    removeToken() {
      this.token = ''
    },
  },
  // use for automatically store data in localStorage/sessionStorage
  persist: piniaPersistConfig('GlobalState'),
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export default pinia
